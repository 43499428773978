import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import QuoteIcon from "components/Icons/QuoteIcon";
import useIsInViewport from "hook/useIsInViewport";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useRef, useState } from "react";
import NodeTestimonyPreviewTeaser from "./NodeTestimonyPreviewTeaser";

export default function NodeTestimonyPreviewList({ nodes }) {
  const [active, setActive] = useState(0);
  const [height, setHeight] = useState(0);
  const { t } = useTranslation("common");
  const [first, last] = [0, nodes.length - 1];
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  useEffect(() => {
    if (ref.current) {
      setHeight((current) =>
        ref.current.offsetHeight > current ? ref.current.offsetHeight : current
      );
    }
  }, [active]);

  return (
    <div
      ref={ref}
      className="relative px-8 lg:px-16 py-16 text-white bg-primary transition-all"
      style={{
        minHeight: height,
        opacity: isInViewport ? 100 : 0,
        transform: `scale(${isInViewport ? 1 : 0.9})`,
        transitionDuration: `${transitionDuration}s`,
        transitionDelay: `0s`,
      }}
    >
      <div className="flex flex-col gap-8">
        <div
          className="flex-initial transition-all"
          style={{
            opacity: isInViewport ? 100 : 0,
            transform: `translateY(${isInViewport ? 0 : "-1.5rem"})`,
            transitionDuration: `${transitionDuration}s`,
            transitionDelay: `${transitionDelay}s`,
          }}
        >
          <QuoteIcon className="h-10 w-10" />
        </div>
        <NodeTestimonyPreviewTeaser key={active} node={nodes[active]} />
      </div>
      {nodes.length > 1 && (
        <div
          className="md:absolute bottom-16 right-16 flex items-center gap-4 mt-8 transition-all"
          style={{
            opacity: isInViewport ? 100 : 0,
            transitionDuration: `${transitionDuration}s`,
            transitionDelay: `${transitionDelay}s`,
          }}
        >
          <button
            type="button"
            className="p-4 bg-white/20 text-white rounded-full hover:bg-primary-dark transition-all duration-500"
            onClick={() =>
              setActive((lastActive) =>
                lastActive === first ? last : lastActive - 1
              )
            }
          >
            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
            <span className="sr-only">{t("utils.previous")}</span>
          </button>
          <button
            type="button"
            className="p-4 bg-white/20 text-white rounded-full hover:bg-primary-dark transition-all duration-500"
            onClick={() =>
              setActive((lastActive) =>
                lastActive === last ? first : lastActive + 1
              )
            }
          >
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
            <span className="sr-only">{t("utils.next")}</span>
          </button>
        </div>
      )}
    </div>
  );
}
