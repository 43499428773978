import React, { useRef } from "react";
import Body from "components/Body";
import Image from "next/image";
import useIsInViewport from "hook/useIsInViewport";

export default function NodeTestimonyPreviewTeaser({ node }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <div ref={ref}>
      <div
        className="prose max-w-none font-important text-white text-xl mb-12 transition-all"
        style={{
          opacity: isInViewport ? 100 : 0,
          transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
          transitionDuration: `${transitionDuration}s`,
          transitionDelay: `${transitionDelay}s`,
        }}
      >
        <Body value={node.body.processed} />
      </div>
      <div className="flex gap-6">
        {node.field_image && (
          <div
            className="flex-initial transition-all"
            style={{
              opacity: isInViewport ? 100 : 0,
              transform: `scale(${isInViewport ? 1 : 0.75})`,
              transitionDuration: `${transitionDuration}s`,
              transitionDelay: `${transitionDelay}s`,
            }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${node.field_image.uri.url}`}
              width={80}
              height={80}
              layout="intrinsic"
              alt={node.field_image?.resourceIdObjMeta.alt}
              className="rounded-full"
            />
          </div>
        )}
        <div className="flex-1 flex flex-col gap-1">
          <strong
            className="transition-all"
            style={{
              opacity: isInViewport ? 100 : 0,
              transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
              transitionDuration: `${transitionDuration}s`,
              transitionDelay: `${transitionDelay}s`,
            }}
          >
            {node.field_name}
          </strong>
          <span
            className="transition-all max-w-[66%]"
            style={{
              opacity: isInViewport ? 100 : 0,
              transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
              transitionDuration: `${transitionDuration}s`,
              transitionDelay: `${transitionDelay}s`,
            }}
          >
            {node.field_function}
          </span>
        </div>
      </div>
    </div>
  );
}
